import React, { useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  Container,
} from "reactstrap";

import Logo from "../../assets/logos/logo.png";
import styles from "./styles.module.scss";

const Navi = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <nav className={styles.sticky}>
      <Container className={styles.container_nav}>
        <Navbar light expand="md">
          <NavLink exact href="/" className="navbar-brand">
            <img
              src={Logo}
              alt="Ideas y Promocionales AM Logo"
              className={styles.logo_img}
            />
          </NavLink>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="mr-auto" navbar>
              <NavItem>
                <NavLink href="/">NUESTROS PROYECTOS</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/productos">NUESTROS PRODUCTOS</NavLink>
              </NavItem>
              <NavItem>
                <NavLink>
                  <a href="mailto:iyp_am@hotmail.com">CONTÁCTANOS </a>
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </Container>
    </nav>
  );
};

export default Navi;
