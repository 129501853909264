import React from "react";

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import styles from "../styles.module.scss";

// Logos
import Andrea from "../../../assets/about_us/clientes_logos/andrea.png";
import AndreBelleza from "../../../assets/about_us/clientes_logos/andrea_belleza_integral.png";
import Chantal from "../../../assets/about_us/clientes_logos/chantal.png";
import Grisi from "../../../assets/about_us/clientes_logos/grisi.png";
import Jade from "../../../assets/about_us/clientes_logos/jade.png";
import Natura from "../../../assets/about_us/clientes_logos/natura.png";
import Oriflame from "../../../assets/about_us/clientes_logos/oriflame.png";
import Puig from "../../../assets/about_us/clientes_logos/puig.png";
import Yanbal from "../../../assets/about_us/clientes_logos/yanbal.png";

const responsiveOptions = {
  0: {
    items: 1,
  },
  400: {
    items: 2,
  },
  600: {
    items: 3,
  },
  1000: {
    items: 4,
  },
};

const client_carousel = () => {
  return (
    <>
      <OwlCarousel
        className="owl-theme"
        loop
        margin={10}
        nav
        autoPlay={true}
        responsive={responsiveOptions}
      >
        <div className="items">
          <img
            src={Andrea}
            alt="Logo Andrea"
            className={styles.item_owl_carousel}
          />
        </div>
        <div className="items">
          <img
            src={AndreBelleza}
            alt="Logo Andrea Belleza"
            className={styles.item_owl_carousel}
          />
        </div>
        <div className="items">
          <img
            src={Chantal}
            alt="Logo Chantal"
            className={styles.item_owl_carousel}
          />
        </div>
        <div className="items">
          <img
            src={Grisi}
            alt="Logo Grisi"
            className={styles.item_owl_carousel}
          />
        </div>
        <div className="items">
          <img
            src={Jade}
            alt="Logo Jade"
            className={styles.item_owl_carousel}
          />
        </div>
        <div className="items">
          <img
            src={Natura}
            alt="Logo Natura"
            className={styles.item_owl_carousel}
          />
        </div>
        <div className="items">
          <img
            src={Oriflame}
            alt="Logo Oriflame"
            className={styles.item_owl_carousel}
          />
        </div>
        <div className="items">
          <img
            src={Puig}
            alt="Logo Puig"
            className={styles.item_owl_carousel}
          />
        </div>
        <div className="items">
          <img
            src={Yanbal}
            alt="Logo Yanbal"
            className={styles.item_owl_carousel}
          />
        </div>
        <div className="items">
          <img
            src={Natura}
            alt="Logo Natura"
            className={styles.item_owl_carousel}
          />
        </div>
      </OwlCarousel>
    </>
  );
};

export default client_carousel;
