import React from "react";
import { Col, Container, Row } from "reactstrap";

import styles from "./styles.module.scss";

//Carousels
import EcoCarousel from "./subcomponents/eco_carousel";
import NormalCarousel from "./subcomponents/normal_carousel";

//Image
import ProductsImg from "../../assets/products/normal_carousel/pngs_combined.png";

const Products = () => {
  return (
    <>
      <Container className={styles.first_to_load}>
        <Row>
          <Col>
            <h1 className={styles.title_div}>NUESTROS PRODUCTOS</h1>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col>
            <p className="text-justify">
              Cada uno de nuestros productos es realizado con la mayor calidad
              posible; tanto del area textil como la de importaciones. Siempre
              buscaremos brindar la mejor calidad al mejor precio.
              Para complementar dichos productos, te daremos asesoramiento para
              asegurarnos que le puedas sacar el mayor provecho.
            </p>
          </Col>
          <Col>
            <img src={ProductsImg} className="w-100" alt="Collage Products" />
          </Col>
        </Row>
        <Row>
          <Col>
            <h1 className={styles.title_div}>PRODUCTOS</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <NormalCarousel />
          </Col>
        </Row>
        <Row>
          <Col>
            <h1 className={styles.title_div}>PRODUCTOS ECOLÓGICOS</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <EcoCarousel />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Products;
