import React from "react";
import { Col, Container, Row } from "reactstrap";
import { FaHeart, FaLinkedin, FaEnvelope } from "react-icons/fa";
import styles from "./styles.module.scss";

import Logo from "../../assets/logos/logo.png";
const Footer = () => {
  return (
    <footer>
      <Container fluid className={styles.container_footer1}>
        <Container>
          <Row className="align-items-center mt-5 mb-4">
            <Col>
              <a href="/">
                <img
                  src={Logo}
                  alt="Logo Ideas y Promocionales AM"
                  className={styles.logo_footer}
                />
              </a>
            </Col>
            <Col>
              <a href="/">
                <p>Acerca de</p>
              </a>
              <a href="mailto:iyp_am@hotmail.com">
                <p>Contáctanos</p>
              </a>
              <a href="/">
                <p>Valores</p>
              </a>
            </Col>
            <Col>
              <a
                href="https://www.linkedin.com/company/ideas-y-promocionales-am-sa-de-cv"
                target="_blank"
                rel="noreferrer"
              >
                <FaLinkedin color="black" size="2.5rem" className="m-2" />
              </a>
              <a href="mailto:iyp_am@hotmail.com">
                <FaEnvelope color="black" size="2.5rem" />
              </a>
              <p className="mt-2">Lunes a Viernes</p>
              <p>De 9:00 a 17:00</p>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid className={styles.container_footer2}>
        <Container>
          <Row>
            <Col>
              <p>Copyright © 2009 - Ideas y Promocionales AM S.A. de C.V.</p>
            </Col>
            <Col>
              <p>
                Made with <FaHeart color="red" size="1rem" /> by{" "}
                <a href="https://anyf.com.mx" target="_blank" rel="noreferrer">
                  ANYF Studios
                </a>
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
    </footer>
  );
};

export default Footer;
