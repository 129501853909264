import React from "react";
import { Container, Row, Col } from "reactstrap";

import styles from "./styles.module.scss";

//Images
import MisionImg from "../../assets/about_us/mision_img.jpg";
import VisionImg from "../../assets/about_us/vision_img.jpg";

//Carousels
import BannerCarousel from "./subcomponents/banner_carousel";
import ClientCarousel from "./subcomponents/client_carousel";

const AboutUs = () => {
  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <BannerCarousel />
          </Col>
        </Row>
      </Container>
      <Container fluid className={styles.contamos_section}>
        <Container>
          <Row>
            <Col>
              <h1>
                Contamos con productos de excelente calidad de origen local y de
                importación
              </h1>
            </Col>
            <Col>
              <ul>
                <li>
                  <p>Garantía de Calidad</p>
                </li>
                <li>
                  <p>Oficinas en China </p>
                </li>
                <li>
                  <p>Buenos Productos</p>
                </li>
                <li>
                  <p>Excelente relación entre calidad y precio</p>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container>
        <Row>
          <Col>
            <h1 className={styles.title_div}>NUESTROS CLIENTES</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <ClientCarousel />
          </Col>
        </Row>
        <Row>
          <Col>
            <h1 className={styles.title_div}>NOSOTROS</h1>
          </Col>
        </Row>
        <Row className="align-items-center my-4">
          <Col>
            <h3>Misión</h3>
            <p>
              ​Proporcionar calidad y servicio a nuestros clientes adaptándonos
              a sus necesidades.
            </p>
          </Col>
          <Col>
            <img
              src={MisionImg}
              alt="Imagen misión"
              className={styles.about_img}
            />
          </Col>
        </Row>
        <Row className="align-items-center my-4">
          <Col>
            <img
              src={VisionImg}
              alt="Imagen misión"
              className={styles.about_img}
            />
          </Col>
          <Col className={styles.center_text}>
            <h3>Visión</h3>
            <p>
              Ser un área más de tu empresa con la cual puedes contar en todo
              momento.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AboutUs;
