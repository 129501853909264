import React from "react";

import styles from "../styles.module.scss";

//Carousel
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

import Carousel1 from "../../../assets/about_us/carousel/container.jpg";
import Carousel2 from "../../../assets/about_us/carousel/couture.jpg";

const carousel = () => {
  return (
    <>
      <Carousel
        dynamicHeight={true}
        emulateTouch={true}
        infiniteLoop={true}
        labels={false}
        stopOnHover={true}
        swipeable={true}
        showThumbs={false}
        showStatus={false}
        className={styles.carousel}
      >
        <div>
          <img
            src={Carousel1}
            className={styles.carousel_img}
            alt="Carousel Principal"
          />
          <div className={styles.carousel_legend}>
            <h1>Ideas y Promocionales AM</h1>
            <hr />
            <h2>
              Más de 10 años de experiencia brindando el mejor servicio en el
              sector de la venta por catalogo
            </h2>
          </div>
        </div>
        <div>
          <img
            src={Carousel2}
            className={styles.carousel_img}
            alt="Carousel Secondary"
          />
          <div className={styles.carousel_legend}>
            <h1>Ideas y Promocionales AM</h1>
            <hr />
            <h2>
              Más de 10 años de experiencia brindando el mejor servicio en el
              sector de la venta por catalogo
            </h2>
          </div>
        </div>
      </Carousel>
    </>
  );
};

export default carousel;
