import React from "react";

import styles from "../styles.module.scss";

//Carousel
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

//Images
import Img1 from "../../../assets/products/normal_carousel/face_roller.jpg";
import Img2 from "../../../assets/products/normal_carousel/knife.jpg";
import Img3 from "../../../assets/products/normal_carousel/metal_utensils.jpg";
import Img4 from "../../../assets/products/normal_carousel/plastic_bags.jpg";
import Img5 from "../../../assets/products/normal_carousel/pngs_combined.png";

const normal_carousel = () => {
  return (
    <>
      <Carousel
        dynamicHeight={true}
        emulateTouch={true}
        infiniteLoop={true}
        labels={false}
        stopOnHover={true}
        swipeable={true}
        showThumbs={true}
        showStatus={false}
        className={styles.carousel}
      >
        <div>
          <img src={Img1} className={styles.carousel_img} alt="Producto 1" />
        </div>
        <div>
          <img src={Img2} className={styles.carousel_img} alt="Producto 2" />
        </div>
        <div>
          <img src={Img3} className={styles.carousel_img} alt="Producto 3" />
        </div>
        <div>
          <img src={Img4} className={styles.carousel_img} alt="Producto 4" />
        </div>
        <div>
          <img src={Img5} className={styles.carousel_img} alt="Producto 5" />
        </div>
      </Carousel>
    </>
  );
};

export default normal_carousel;
