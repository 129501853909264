import styles from "./styles.module.scss";

import { Route, Switch } from "react-router-dom";

import Navbar from "../navbar/navbar";
import AboutUs from "../about_us/about_us";
import Products from "../products/products";
import Footer from "../footer/footer";
import ScrollToTop from "../scroll_to_top/scroll_to_top";

function App() {
  return (
    <>
      <Navbar />
      <div className={styles.App}>
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={AboutUs} />
          <Route path="/productos" component={Products} />
        </Switch>
      </div>
      <Footer />
    </>
  );
}

export default App;
